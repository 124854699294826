import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import { Card as MuiCard, Grid } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { spacing } from "@mui/system";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";

const Card = styled(MuiCard)(spacing);

function ArtistList() {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "artistName",
      headerName: "Name",
      flex: 0.8,
      editable: false,
    },
    {
      field: "promoter",
      headerName: "Promoter",
      flex: 0.5,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
      editable: false,
    },
    {
      field: "userName",
      headerName: "User name",
      flex: 0.5,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate(`/artists/edit-artist`, {
              state: {
                artist: {
                  ...params.row,
                },
              },
            });
          }}
          label="Edit"
        />,
      ],
    },
  ];
  return (
    <React.Fragment>
      <OlogaPageHeader
        title="Artist"
        buttonLabel="New Artist"
        onClick={() => navigate("/artists/add-artist")}
      />

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <OlogaDataGrid
              url="/artists"
              columns={columns}
              exportToExcel={true}
              fileName="Artists"
              excludeFromExport={["edit", "actions", "hide"]}
            />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ArtistList;
