import { Card, Grid, CardContent } from "@mui/material";

import ArtistForm from "./ArtistForm";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { useLocation } from "react-router-dom";

function UpdateArtist() {
  const { state } = useLocation();
  return (
    <>
      <OlogaPageHeader
        title="Edit Artist"
        items={[{ label: "Artist", url: "/artist" }]}
      />

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <ArtistForm artist={state.artist} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default UpdateArtist;
