import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { Card as MuiCard, Grid, Fade, CircularProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import http from "../../utils/axios";

const Card = styled(MuiCard)(spacing);

interface ArtistEventsProps {
  artistId: number;
}

interface ArtistParticipationEventsProps {
  id: number;
  eventId: number;
  artistId: number;
  eventName: string;
  active: boolean;
  startDate: string;
}

function ArtistEvents({ artistId }: ArtistEventsProps) {
  const [eventData, setEventData] = useState<ArtistParticipationEventsProps>();
  const [loading, setLoading] = useState(true);

  const columns: GridColDef[] = [
    {
      field: "eventName",
      headerName: "Event",
      flex: 1,
      editable: false,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
      editable: false,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const url = `/participations/artist/${artistId}`;
      try {
        const result = await http.get(url);
        const newData = result.data;
        setEventData(newData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [artistId]);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <Fade in={loading} unmountOnExit>
              <CircularProgress />
            </Fade>
            {!loading && (
              <OlogaDataGrid
                existingData={eventData}
                paginationMode="client"
                columns={columns}
                exportToExcel={true}
                fileName="Artist Events"
              />
            )}
          </Card>
          <Card></Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ArtistEvents;
