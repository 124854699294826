import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";

import { Grid } from "@mui/material";
import http from "../../utils/axios";
import OlogaButtonGroup from "../../components/buttonsx/OlogaButtonGroup";
import { passwordRules, artistSchema } from "../../utils/formValidation";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import OlogaSelect from "../../components/select/OlogaSelect";
import ArtistEvents from "./ArtistEvents";
import { toast } from "react-toastify";

type participationsProps = {
  id: number;
  updated: boolean;
  vendorId: null | number;
  eventId: number;
  merchantId: null | number;
  artistId: number;
  participantType: string;
  active: boolean;
};
type artistProps = {
  id: string;
  artistName: string;
  brandId: number;
  email: string;
  promoter: string;
  userName: string;
  participations: participationsProps[];
};
type ArtistFormProps = {
  artist?: artistProps;
};

type companyProps = {
  id: number;
  name: string;
  description?: string;
};

const ArtistForm = ({ artist }: ArtistFormProps) => {
  const [companies, setCompanies] = useState<companyProps[]>([]);
  const navigate = useNavigate();
  const artistId = parseInt(artist?.id || "0");

  const getArtistPromoter = (name: string) => {
    const promoter = companies.find(
      (company: companyProps) => company.name === name
    );

    return promoter ? promoter?.id : "";
  };

  const handleSubmit = async (
    values: any,
    { setErrors, setStatus, setSubmitting, resetForm }: any
  ) => {
    try {
      const url = "/artists";
      const { confirmPassword, ...formValues } = values;
      if (artist) {
        const updatedFormValues = {
          ...formValues,
          id: parseInt(artist.id),
        };
        await http.put(url, updatedFormValues);
      } else {
        await http.post(url, formValues);
      }
      setStatus({ sent: true });
      setSubmitting(false);
      resetForm();
      toast.success("Artist saved successfully");
      navigate("/artists");
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({
        submit: error.message || "Error submitting form",
      });
      toast.error(error.message || "Error submitting form");
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const url = "/companies/promoters/names";
      try {
        const result = await http.get(url);
        setCompanies(result.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Grid item container>
      <Grid item xs={12}>
        <Formik
          initialValues={{
            artistName: artist ? artist.artistName : "",
            userName: artist ? artist.userName : "",
            password: "",
            confirmPassword: "",
            promoterId: artist ? getArtistPromoter(artist.promoter) : "",
            email: artist ? artist.email : "",
          }}
          validationSchema={
            artist ? artistSchema : artistSchema.concat(passwordRules)
          }
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <OlogaTextfield name="artistName" label="Artist Name" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <OlogaTextfield name="email" label="Email" />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <OlogaTextfield name="userName" label="Username" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <OlogaSelect
                    name="promoterId"
                    label="Promoter"
                    options={companies}
                  />
                </Grid>
              </Grid>
              {!artist && (
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <OlogaTextfield
                      name="password"
                      type="password"
                      label="Password"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <OlogaTextfield
                      name="confirmPassword"
                      type="password"
                      label="Confirm Password"
                    />
                  </Grid>
                </Grid>
              )}
              <OlogaButtonGroup />
            </form>
          )}
        </Formik>
      </Grid>
      <Grid item xs={12} mt={10}>
        {artist && <ArtistEvents artistId={artistId} />}
      </Grid>
    </Grid>
  );
};

export default ArtistForm;
