import { Card, Grid, CardContent } from "@mui/material";

import ArtistForm from "./ArtistForm";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";

function CreateArtist() {
  return (
    <>
      <OlogaPageHeader
        title="Add Artist"
        items={[{ label: "Artist", url: "/artist" }]}
      />

      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <ArtistForm />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateArtist;
