import React from "react";
import {
  TextField as MuiTextField,
  MenuItem,
  TextFieldProps,
} from "@mui/material";
import { useField, useFormikContext } from "formik";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface Item {
  id: number;
  name: string;
  description?: string;
}

export type OlogaSelectProps = {
  name: string;
  options: Item[];
  setValue?: (value: any) => void;
} & TextFieldProps;

const OlogaSelect: React.FC<OlogaSelectProps> = ({
  name,
  options,
  setValue,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext<any>();
  const [field, meta] = useField(name);

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setFieldValue(name, value);
    setValue && setValue(value);
  };

  const configSelect: TextFieldProps = {
    ...field,
    ...otherProps,
    select: true,
    variant: "outlined",
    fullWidth: true,
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }

  return (
    <TextField {...configSelect} my={2}>
      {options.map((option) => {
        return (
          <MenuItem key={option.id} value={option.id}>
            {option.name ? option.name : option.description}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default OlogaSelect;
