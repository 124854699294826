import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Grid,
  Button,
  Typography,
  TextField,
  FormControl,
  Input,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
} from "@mui/material";
import { color, spacing } from "@mui/system";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import OlogaGenericCombo from "../../components/combosx/OlogaGenericCombo";
// import OlogaMultiInput from "../../components/Combos/OlogaMultiInput";
import Item from "../../components/combosx/Item";
import useAuth from "../../hooks/useAuth";
import React, { useState, useEffect, ChangeEvent } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import CancelButton from "../../components/buttonsx/CancelButton";
import SubmitButton from "../../components/buttonsx/SubmitButton";
import axios from "../../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ForkLeft } from "@mui/icons-material";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import SettingsModal from "../settings/SettingsModal";
import OlogaMultiInput from "../../components/combosx/OlogaMultiInput";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { margin } from "polished";
import EventProductQuantityModal from "./EventProductQuantityModal";
import ImageCompressor from "../../hooks/ImageCompressor";
import { number } from "yup";
import OlogaLoading from "../../components/misc/OlogaLoading";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const EventProductEdit: React.FC = () => {
  const initialProductData = {
    id: null,
    barCode: null,
    productDescription: null,
    quantity: null,
    price: 0,
    cost: 0,
    event: null,
    vendor: null,
    imageUri: "",
    product: {
      category: { vendor: null },
      brand: { vendor: null },
    },
    file: null,
    sizes: [],
  };

  const { user } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [events, setEvents] = useState([]);
  const [media, setMedia] = useState<FormData>();
  const { eventProductId } = useParams<{ eventProductId: string }>();
  const [productCategories, setProductCategories] = useState<Item[]>([]);
  const [productBrands, setProductBrands] = useState<Item[]>([]);
  const [productSizes, setProductSizes] = useState<Item[]>([]);
  const [productData, setProductData] = useState(initialProductData);
  const [productDataUnChanged, setProductDataUnChanged] =
    useState(initialProductData);
  const [sizeType, setSizeType] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [isValueChanged, setIsValueChanged] = useState<boolean>(false);
  const [previousValue, setPreviousValue] = useState();
  const [currentValue, setCurrentValue] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState<
    string | ArrayBuffer | null
  >(null);
  const handleCloseSettingModal = () => {
    retriveDropdownsData();

    if (
      productData != null &&
      productData.product != null &&
      productData.product.category != null
    ) {
      retrieveProductSizes(
        productData,
        (productData.product.category as any).id
      );
    }
  };

  function retriveDropdownsData() {
    retrieveProductCategories();
    retrieveProductBrands();
    retrieveEvents();
  }

  useEffect(() => {
    setSizeType(state ? ("M" as any) : ("S" as any));

    if (state) {
      retrieveEventProduct(state.eventProductId as string);
    } else {
      const prodData = initialProductData;
      prodData.sizes = [];
      setProductData(prodData);
    }
    retriveDropdownsData();
  }, []);

  const handleSizeTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (value === "M") {
      updateVariable(productData, "quantity", 0, null);
    }

    setSizeType(value as any);
  };

  const handleSelectCategoryChange = (event: Item) => {
    updateVariable(productData, event.name, event, null);
    retrieveProductSizes(productData, event.id);
    productData.sizes = [];
    setProductData(productData);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSubmitted(false);
    const { name, value } = event.target;
    updateVariable(productData, name, value, null);
  };

  const handleSelectChange = (event: Item) => {
    updateVariable(productData, event.name, event, null);
  };

  const handleMultiSelectChange = (
    componentName: string,
    selected: Item[],
    e: any
  ) => {
    updateVariable(productData, componentName, selected, e);
  };

  const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileBytes = event.target.files?.[0]; // Get the first selected file

    if (fileBytes) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target?.result || null);
      };
      reader.readAsDataURL(fileBytes as Blob);
    }

    const formData = await ImageCompressor(
      fileBytes as Blob,
      fileBytes?.name as string
    );

    setMedia(formData);
    updateVariable(productData, "file", formData, null);
  };

  const updateVariable = (
    object: any,
    propertyName: string,
    newValue: any,
    e: any
  ) => {
    const propertyPath = propertyName.split(".");
    let currentObject: any = { ...object };
    updateCurrentAndPreviousValues(object, propertyName, newValue, e);

    if (propertyPath.length > 1) {
      for (let i = 0; i < propertyPath.length - 1; i++) {
        currentObject = currentObject[propertyPath[i]];
      }
      currentObject[propertyPath[propertyPath.length - 1]] = newValue;
      currentObject["updated"] =
        propertyName === "sizes" || propertyName == "quantity" ? 1 : 0;
      setProductData({ ...object });
    } else if (propertyPath.length == 1) {
      currentObject[propertyName] = newValue;
      currentObject["updated"] =
        propertyName === "sizes" || propertyName == "quantity" ? 1 : 0;
      setProductData({ ...currentObject });
    }
  };

  const updateCurrentAndPreviousValues = (
    object: any,
    propertyName: string,
    newValue: any,
    e: any
  ) => {
    if (propertyName === "quantity" && state?.eventProductId) {
      setIsValueChanged(true);
      setPreviousValue((productDataUnChanged as any)[propertyName]);
      setCurrentValue(newValue);
    }
    if (propertyName === "sizes" && state?.eventProductId) {
      setIsValueChanged(true);
      const oldSize = productDataUnChanged.sizes.filter(
        (a: any) => a.id === e.target.id.replace("q-", "")
      )[0];
      setPreviousValue((oldSize as any).quantity);

      const newSize = newValue.filter(
        (b: any) => b.id === e.target.id.replace("q-", "")
      )[0];
      setCurrentValue((newSize as any).quantity);
    }
  };

  const retrieveEventProduct = async (id: string) => {
    if (!id) {
      return;
    }
    const response = await axios.get("/eventProduct/" + id);
    setProductData(response.data);
    setProductDataUnChanged(response.data);
    setSizeType(response.data.sizes.length > 0 ? ("M" as any) : ("S" as any));
    retrieveProductSizes(response.data, response.data.product.category.id);
    return response;
  };

  const retrieveEvents = async () => {
    const response = await axios.get(`/events/names`);
    setEvents(
      response.data.map((r: Item) => {
        return {
          id: r.id,
          description: r.name,
          type: r.type,
          name: "event",
        };
      })
    );
  };

  const retrieveProductCategories = async () => {
    const response = await axios.get(
      "/simple-entity/product-sub-categories/vendor"
    );
    setProductCategories(
      response.data.map((r: Item) => ({
        id: r.id,
        description: r.description,
        type: r.type,
        name: "product.category",
      }))
    );
  };

  const retrieveProductBrands = async () => {
    const response = await axios.get("/simple-entity/product-brands/vendor");
    setProductBrands(
      response.data.map((r: Item) => ({
        id: r.id,
        description: r.description,
        type: r.type,
        name: "product.brand",
      }))
    );
  };

  const retrieveProductSizes = async (productData: any, id: number) => {
    const response = await axios.get(
      `/simple-entity/product-sizes/parent/${id}`
    );

    // Add sizes on Product edition
    if (state) {
      response.data.map((size: Item) => {
        const found = productData.sizes.find(
          (productSize: any) => productSize.sizeId === size.id
        );

        if (!found) {
          productData.sizes.push(size as never);
        }
      });
      setProductSizes(productData.sizes);
    } else {
      // Fetch all sizes on Product creation
      setProductSizes(
        response.data.map((r: Item) => ({
          id: r.id,
          description: r.description,
          type: r.type,
          quantity: "",
        }))
      );
    }
  };

  // Function to handle form submission
  const handleSubmit = async (event: React.FormEvent) => {
    setShowLoading(true);
    setSubmitted(true);

    try {
      event.preventDefault();

      if (!hasValidationErrors()) {
        productData.vendor = null;

        if (productData.id !== null) {
          productData.product.category.vendor = null;
          productData.product.brand.vendor = null;
        }
        const eventProductCreated = await axios.post(
          "eventProduct/single",
          productData
        );

        if (media) {
          await axios.post(
            "media/upload/productimage/" +
              2 +
              "/" +
              eventProductCreated.data.id,
            media,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        }

        toast.success("Product saved successfully");

        if ((event.target as any).outerText === "Save and Create New") {
          navigate("/products/create", { replace: true });
          setProductData(initialProductData);
        } else {
          navigate("/products/search", { replace: true });
        }
      }
    } catch (error) {
      toast.error((error as any).message);
    }

    setShowLoading(false);
  };

  const hasValidationErrors = () =>
    !productData.productDescription ||
    !productData.cost ||
    isNaN(Number(productData.cost)) ||
    !productData.price ||
    isNaN(Number(productData.price));

  return (
    <React.Fragment>
      <OlogaPageHeader
        title={state?.eventProductId ? "Edit Product" : "Add Product"}
        items={[{ label: "Products", url: "/products/search" }]}
      />

      <Card mb={12}>
        <OlogaLoading open={showLoading} />
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} marginBottom={3}>
              <Grid item xs={5}>
                <OlogaGenericCombo
                  label="Select a Category"
                  name="product.category"
                  items={productCategories}
                  value={productData.product.category || ""}
                  onChange={handleSelectCategoryChange}
                  required={true}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginLeft: "0px",
                }}
              >
                <Grid item className="form-buttons">
                  <SettingsModal
                    btnType="ListSetting"
                    btnIcon={<VisibilityOutlinedIcon />}
                    listSettingRender="hasProductCategoryModal"
                    btnName=""
                    title="Categories"
                    type="PRODUCT_SUB_CATEGORY"
                    onClosemodal={handleCloseSettingModal}
                  />
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <OlogaGenericCombo
                  label="Select a Brand"
                  name="product.brand"
                  items={productBrands}
                  value={productData.product.brand || ""}
                  onChange={handleSelectChange}
                  required={true}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginLeft: "0px",
                }}
              >
                <Grid item className="form-buttons">
                  <SettingsModal
                    btnType="ListSetting"
                    btnIcon={<VisibilityOutlinedIcon />}
                    listSettingRender="hasProductBrandModal"
                    btnName=""
                    title="Brands"
                    type="PRODUCT_BRAND"
                    onClosemodal={handleCloseSettingModal}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Product Name"
                  name="productDescription"
                  variant="outlined"
                  value={productData.productDescription || ""}
                  onChange={handleInputChange}
                  required
                  error={submitted && !productData.productDescription}
                  helperText={
                    submitted &&
                    !productData.productDescription &&
                    "This field is required"
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <OlogaGenericCombo
                  label="Select a Event"
                  name="event"
                  items={events}
                  value={productData.event || ""}
                  onChange={handleSelectChange}
                  required={true}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Cost (ZAR)"
                  variant="outlined"
                  value={
                    productData.cost?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    }) || ""
                  }
                  onChange={handleInputChange}
                  name="cost"
                  required
                  error={submitted && !productData.cost}
                  helperText={
                    submitted && !productData.cost && "This field is required"
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Unit Price (ZAR)"
                  variant="outlined"
                  value={
                    productData.price?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    }) || ""
                  }
                  onChange={handleInputChange}
                  name="price"
                  required
                  error={submitted && !productData.price}
                  helperText={
                    submitted && !productData.price && "This field is required"
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Bar Code"
                  variant="outlined"
                  value={productData.barCode || ""}
                  onChange={handleInputChange}
                  name="barCode"
                />
              </Grid>
              <Grid item xs={6} marginBottom={3}>
                <RadioGroup
                  aria-label="options"
                  name="options"
                  value={
                    sizeType != "S" && sizeType != "M"
                      ? productData.sizes.length > 0
                        ? "M"
                        : "S"
                      : sizeType
                  }
                  onChange={handleSizeTypeChange}
                >
                  <FormControlLabel
                    value="S"
                    control={<Radio />}
                    label="Single Size Product"
                  />
                  <FormControlLabel
                    value="M"
                    control={<Radio />}
                    label="Multiple Size Product"
                  />
                  {sizeType === "M" && (
                    <Grid item xs={3} marginLeft={4}>
                      <SettingsModal
                        btnName="Add Produt Size"
                        title="Add Product Size"
                        type="PRODUCT_SIZE"
                        parentId={
                          productData != null &&
                          productData.product != null &&
                          productData?.product?.category
                            ? (productData.product.category as any).id
                            : null
                        }
                        onClosemodal={handleCloseSettingModal}
                      />
                    </Grid>
                  )}
                </RadioGroup>
              </Grid>
              <Grid item xs={11}>
                {sizeType === "S" && (
                  <TextField
                    fullWidth
                    label="Product Quantity"
                    name="quantity"
                    variant="outlined"
                    value={productData.quantity || ""}
                    onChange={handleInputChange}
                    required
                    error={submitted && !productData.quantity}
                    helperText={
                      submitted &&
                      !productData.quantity &&
                      "This field is required"
                    }
                  />
                )}
              </Grid>

              {sizeType === "M" && (
                <Grid item xs={12}>
                  <OlogaMultiInput
                    label="Product Sizes"
                    componentName="sizes"
                    options={productSizes}
                    selectedOptions={productData.sizes || ""}
                    onChange={handleMultiSelectChange}
                  />
                </Grid>
              )}

              {isValueChanged && (
                <Typography color="error" marginLeft={3}>
                  Warning: The quantity typed [{currentValue}] will be added to
                  the existent [{previousValue as any}]. The final avaliable
                  stock will be [{Number(currentValue) + Number(previousValue)}
                  ].
                </Typography>
              )}

              <Grid item xs={12}>
                <h3>Upload Product Image</h3>
                <FormControl fullWidth>
                  <Input
                    type="file"
                    //accept="image/*"
                    onChange={handleImageUpload}
                  />
                </FormControl>
                {(selectedImage || productData.imageUri) && (
                  <div>
                    <h2>Uploaded Image:</h2>
                    <img
                      src={
                        selectedImage
                          ? (selectedImage as string)
                          : productData.imageUri
                      }
                      width="15%"
                      height="auto"
                    ></img>
                  </div>
                )}
              </Grid>
              <br />
              <br />
              <Grid
                container
                marginTop={5}
                spacing={3}
                mt={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid item className="form-buttons">
                  <SubmitButton
                    label={"Save and Create New"}
                    onClick={handleSubmit}
                  />
                </Grid>
                <Grid item className="form-buttons">
                  <SubmitButton label={"Save"} onClick={handleSubmit} />
                </Grid>
                <Grid item marginRight={2}>
                  <CancelButton navigateTo={"../search"} />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default EventProductEdit;
